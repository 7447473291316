import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Typography, Box, Container, Paper, Grid, ButtonBase } from "@mui/material";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { openVideoPopup } from "../../components/VideoPopup/openVideoPopup";
import VideoPopup from "../../components/VideoPopup/VideoPopup";
import "./Totem.view.css";

function TotemView({ state, refs, callbacks }) {
    const popup = useRef(null);
    const root = useRef(null);
    const videoSetting = state?.user?.settings?.find((setting) => setting?.nameId === 'VIDEO_URL');
    const popupSetting = state?.user?.settings?.find((setting) => setting?.nameId === 'POPUP');
    const requestAssistanceButtonSetting = state?.user?.settings?.find((setting) => setting?.nameId === 'REQUEST_ASSISTANCE_BUTTON');
    const requestAssistanceButtonAnimationSetting = state?.user?.settings?.find((setting) => setting?.nameId === 'REQUEST_ASSISTANCE_BUTTON_ANIMATION');
    const videoUrl = videoSetting?.value || './santander_video.mp4';
    const popupEnabled = popupSetting?.value ? Boolean(popupSetting?.value) : false;
    const requestAssistanceButton = requestAssistanceButtonSetting?.value ? Boolean(requestAssistanceButtonSetting?.value) : false;
    const requestAssistanceButtonAnimation = requestAssistanceButtonAnimationSetting?.value || '';

    useEffect(() => {
        if (!popupEnabled) {
            return;
        }
        popup.current = openVideoPopup();

        root.current = popup.current.window.document.createElement('div');
        popup.current.window.document.body.appendChild(root.current);

        window.addEventListener('beforeunload', () => {
            if (popup.current) {
                popup.current.close();
            }
        })
    }, [refs.userVideo, popupEnabled]);

    useEffect(() => {
        return () => {
            if (popup.current) {
                popup.current.close();
            }
        };
    }, []);

    return (
      <Container
        maxWidth="sm"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {root.current &&
            createPortal(
              <VideoPopup
                videoUrl={videoUrl}
                videoRef={refs.userVideo}
                callAccepted={state.callAccepted}
                userOpenCamera={state.userOpenCamera}
              />,
              root.current,
            )}
        </div>
        <div className="divider"></div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Paper elevation={3} className="video-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="video">
                  {state.stream && (
                    <video
                      playsInline
                      muted
                      ref={refs.myVideo}
                      autoPlay
                      style={{ width: "100%" }}
                    />
                  )}
                  <Box display="flex" justifyContent="center">
                    <Typography
                      sx={{ color: "#ec0000", fontWeight: "bold" }}
                      variant="h6"
                    >
                      {state?.user?.name}
                    </Typography>
                  </Box>
                </div>
              </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" marginBottom={2}>
                        {requestAssistanceButton && state.callAccepted && (
                            <ButtonBase
                                sx={{ fontSize: 16 }}
                                onClick={callbacks.handleRequestAssistance}
                            >
                               <Box display="flex" alignItems="center" flexDirection="column" sx={{ border: "1px solid #ec0000", padding: 2, borderRadius: 5 }}>
                                   <Player
                                       autoplay
                                       loop
                                       src={requestAssistanceButtonAnimation}
                                       style={{ height: '150px', width: '150px' }}
                                   >
                                       <Controls visible={false} />
                                   </Player>
                                   <Typography
                                       sx={{ color: "#ec0000", fontWeight: "bold" }}
                                       variant="h6"
                                   >
                                       Solicitar asistencia
                                   </Typography>
                                 </Box>
                            </ButtonBase>
                        )}
                    </Box>
                </Grid>
              <Grid item xs={12}>
                <div className="video">
                  {state.callAccepted && !state.callEnded ? (
                    <video
                      playsInline
                      ref={refs.userVideo}
                      autoPlay
                      style={{ width: "100%", display: state.userOpenCamera ? 'block' : 'none' }}
                    />
                  ) : null}
                  <Box display="flex" justifyContent="center">
                    <Typography
                      sx={{ color: "#ec0000", fontWeight: "bold" }}
                      variant="h6"
                      style={{ display: state.callAccepted ? 'block' : 'none' }}
                    >
                      {state.userOpenCamera ? state.connectedUserName : ''}
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="video">
                  <video
                    playsInline
                    src={videoUrl}
                    autoPlay
                    loop={true}
                    muted={state.userOpenMicrophone}
                    style={{ width: "100%" }}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Box mt={2} className="myId"></Box>
        </Box>
      </Container>
    );
}

export default TotemView;